
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      { path: 'model', component: () => import('@/pages/ModelPage.vue') },
      { path: '', component: () => import('@/pages/infoPage.vue') },
      { path: 'info', component: () => import('@/pages/ModelInfoPage.vue') },
      { path: 'video', component: () => import('@/pages/VideoPage.vue') },
      { path: 'help', component: () => import('@/pages/HelpPage.vue') },
      { path: 'result', component: () => import('@/pages/ResultPage.vue') },
      { path: 'download', component: () => import('@/pages/DownloadPage.vue') },
      { path: 'forbidden', name: "Forbidden", component: () => import('@/pages/Forbidden.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: "ErrorNotFound",
    component: () => import('@/pages/ErrorNotFound.vue')
  }
]

export default routes
