import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { vueKeycloak } from '@baloise/vue-keycloak'
import { createPinia } from 'pinia'
import router from '@/router'

const app = createApp(App).use(createPinia())

app.use(Quasar, quasarUserOptions)


app.use(vueKeycloak, {
  initOptions: {
    flow: 'standard', // default
    checkLoginIframe: false, // default
    onLoad: 'login-required', // default
    // onLoad: 'check-sso', // default,
    silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
  },
  config: {
    url: 'https://ext-auth.vostokgosplan.ru/auth',
    realm: 'master',
    // realm: 'DIGITAL',
    // clientId: 'test',
    clientId: 'demography-ui',
  },
})

app.use(router)

app.mount('#app')
