import {createRouter, createWebHistory} from "vue-router";
import routes from "./routes";
import {checkAuth, postAuthHook} from "@/router/hooks";

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(postAuthHook)
router.beforeResolve(checkAuth)
export default router;
