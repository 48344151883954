import {isTokenReady, useKeycloak} from '@baloise/vue-keycloak'

export const postAuthHook = ({path, hash}, _, next) => {
  if (hash.startsWith('#state')) return next(path)
  return next(true)
}

// eslint-disable-next-line no-unused-vars
export const checkAuth = async ({matched, name, fullPath}, _, next, redirect) => {
  await isTokenReady()
  // eslint-disable-next-line no-unused-vars
  const { isAuthenticated, keycloak } = await useKeycloak()

  if (isAuthenticated) {
    const roles = keycloak.tokenParsed.realm_access.roles
    if (!roles.includes("DEMOGRAPHY_USER") && !fullPath.includes('forbidden')) {
      console.log("forbidden")
      return next("/forbidden")
    }
  }
  return next()
}
